<template>
    <section
        :class="'text-'+ textColor"
        class="accordion pt-10 pb-12 lg:pt-16 lg:pb-20 overflow-hidden"
        :style="'background-color : '+ backgroundColor"
    >
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col md:w-10/12 mx-auto">
                    <div class="accordion__content border-b border-grey-60">
                        <div v-for="(panel, key) in panels"
                             :key="key"
                             ref="panels"
                             class="accordion__panel border-t border-grey-60"
                        >
                            <div class="accordion__opener py-4 cursor-pointer flex justify-between items-center" @click="panelClickHandler">
                                <h4 class="uppercase">{{ panel.heading }}</h4>
                                <svg class="accordion__opener-cross" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 3v8h8v2h-8v8h-2v-8H3v-2h8V3h2z" fill="#000" fill-rule="evenodd"/>
                                </svg>
                            </div>
                            <div class="accordion__content overflow-hidden h-0">
                                <div class="accordion__content-inner pb-6">
                                    <div class="body2" v-html="panel.text"></div>
                                    <a :href="panel.cta.value" target="panel.cta.value" class="mt-4">{{ panel.cta.customText }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import gsap from 'gsap';

    export default {
        name : 'accordion',

        props : {
            // The accordion panels
            panels : { type : Array, default : () => []},

            // Option to have the first panel opened at mount
            firstPanelOpen : { type : Boolean, default : false},

            // Option to close the other panels when opening a new one
            openCloseAll : { type : Boolean, default : false},

            // Background color for the block
            backgroundColor : { type : String, default : 'light'},

            // Text color for the block
            textColor : { type : String, default : 'dark'}
        },

        mounted(){
            // If we want to open the first panel at mount
            if(this.firstPanelOpen) {
                // Show the first panel
                this.togglePanel(this.$refs.panels[0], false);
            }

            // Add a resize event
            window.addEventListener('resize', this.resizeHandler);
        },

        methods : {
            /**
             * Event fired when a panel opener is clicked
             * @param pEvent
             */
            panelClickHandler(pEvent){
                // Get panel
                let panel = pEvent.target.parentNode.closest('.accordion__panel');

                // Toggle the panel
                this.togglePanel(panel, !panel.classList.contains('opened'));
            },

            /**
             * Open/close the panel
             */
            togglePanel(pPanel, pIterate){
                // Get the elements to update
                let panel = pPanel;
                let cross = panel.querySelector('.accordion__opener-cross');
                let content = panel.querySelector('.accordion__content');
                let inner = panel.querySelector('.accordion__content-inner');

                // If we want to close all the panel white opening this one
                if(this.openCloseAll && pIterate) {
                    // Go through opened panels
                    this.$refs.panels.filter((o) => o.classList.contains('opened')).forEach((pItem) => {
                        // Close it
                        this.togglePanel(pItem, false);
                    });
                }

                // Rotate the cross/plus
                gsap.to(cross, {
                    rotation : panel.classList.contains('opened') ? '0deg' : '45deg',
                    duration : 0.3,
                    ease : 'power2.out'
                });

                // Show/hide the content
                gsap.to(content, {
                    height : panel.classList.contains('opened') ? 0 : inner.clientHeight,
                    duration : 0.3,
                    ease : 'power3.out'
                });

                // Toggle the class opened
                panel.classList.toggle('opened');
            },

            /**
             * On window resize
             */
            resizeHandler(){
                // Go through opened panels
                this.$refs.panels.filter((o) => o.classList.contains('opened')).forEach((pItem) => {
                    let content = pItem.querySelector('.accordion__content');
                    let inner = pItem.querySelector('.accordion__content-inner');

                    // Resize the height of the opened content
                    gsap.set(content, {
                        height : inner.clientHeight,
                    });
                });
            }
        }
    }
</script>
