var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"card-carousel pt-10 pb-12 lg:pt-16 lg:pb-20 overflow-hidden",class:[
        {
            'card-carousel--large-image-highlight' : _vm.carouselType && !_vm.textHighlight,
            'card-carousel--large' : _vm.carouselType
        },
        'text-'+ _vm.textColor],style:('background-color : '+ _vm.backgroundColor)},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"grid-col w-full mx-auto relative"},[_c('vue-glide',{staticClass:"card-carousel__content",attrs:{"breakpoints":_vm.breakpoints},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},_vm._l((_vm.cards),function(card,key){return _c('vue-glide-slide',{key:key,staticClass:"card overflow-hidden",class:{
                            'px-7 pt-6 pb-10 lg:px-8 lg:pt-8 lg:pb-12 bg-grey-95' : _vm.textHighlight,
                            'bg-white filter drop-shadow rounded-md' : !_vm.textHighlight
                        }},[_c('div',{staticClass:"card__image overflow-hidden w-full",class:{
                                'card__image--large' : _vm.carouselType,
                                'card__image--image-highlight' : !_vm.textHighlight,
                                'flex justify-center mb-4 lg:mb-6' : _vm.textHighlight,
                            }},[_c('picture',[(card.imageDesktop && _vm.carouselType)?_c('source',{attrs:{"srcset":card.imageDesktop.url,"media":"(min-width: 768px)"}}):_vm._e(),(card.imageMobile)?_c('img',{class:{'object-cover w-full h-full' : !_vm.textHighlight},attrs:{"src":card.imageMobile.url,"alt":card.imageMobile.alt}}):_vm._e()])]),_c('div',{staticClass:"card__text-content flex flex-col justify-between",class:{'items-center text-center' : _vm.textHighlight, 'p-4 md:p-6' : !_vm.textHighlight}},[_c('div',[_c('h2',{class:{'h2' : _vm.textHighlight, 'h4 uppercase' : !_vm.textHighlight}},[_vm._v(_vm._s(card.heading))]),_c('div',{class:{'body1 mt-4 lg:mt-6' : _vm.textHighlight, 'body2 mt-2 md:mt-4' : !_vm.textHighlight},domProps:{"innerHTML":_vm._s(card.text)}})]),(card.cta && card.cta.value)?_c('a',{staticClass:"block ",class:{'btn btn--black mt-6 lg:mt-8' : _vm.textHighlight, 'link link--S mt-9' : !_vm.textHighlight},attrs:{"href":card.cta.value,"target":card.cta.target}},[_vm._v(_vm._s(card.cta.customText))]):_vm._e()])])}),1),_c('div',{staticClass:"card-carousel__controls flex justify-center items-center mt-6 lg:mt-8"},[_c('button',{staticClass:"card-carousel__arrow card-carousel__prev w-12 h-12 rounded-full flex justify-center items-center flex-shrink-0",class:{'bg-grey-60' : _vm.currentIndex === 0, 'bg-black' : _vm.currentIndex !== 0},on:{"click":function($event){_vm.currentIndex--}}},[_c('svg',{attrs:{"width":"24","height":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.631 3.293.924 12l8.707 8.707 1.414-1.414L4.753 13h18.565v-2H4.753l6.292-6.293-1.414-1.414z","fill":"#FFF","fill-rule":"evenodd"}})])]),_c('div',{staticClass:"card-carousel__dots flex items-center mx-4 lg:mx-8"},_vm._l((_vm.cards),function(card,index,key){return _c('button',{key:key + '-dot-'+ index,staticClass:"h-1 bg-grey-60",class:[
                                'w-1/' + _vm.cards.length,
                                {'rounded-l-full' : index === 0, 'rounded-r-full' : index === _vm.cards.length - 1, 'bg-black' : index === _vm.currentIndex}
                            ],on:{"click":function($event){_vm.currentIndex = index}}})}),0),_c('button',{staticClass:"card-carousel__arrow card-carousel__next w-12 h-12 rounded-full flex justify-center items-center flex-shrink-0",class:{'bg-grey-60' : _vm.currentIndex === _vm.cards.length -1, 'bg-black' : _vm.currentIndex < _vm.cards.length -1},on:{"click":function($event){_vm.currentIndex++}}},[_c('svg',{attrs:{"width":"24","height":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.369 3.293 23.076 12l-8.707 8.707-1.414-1.414L19.247 13H.682v-2h18.565l-6.292-6.293 1.414-1.414z","fill":"#FFF","fill-rule":"evenodd"}})])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }