import "./assets/styles/main.scss";

import Vue from "vue";
import CsrfInput from "@/app/components/CsrfInput";
import HeaderCustom from "@/app/components/HeaderCustom";
import Accordion from "@/app/components/Accordion";
import CardCarousel from "@/app/components/CardCarousel";
import CollapsePanel from "@/app/components/CollapsePanel";
import Media from "@/app/components/Media";

// VUE GLIDE (CAROUSEL)
import VueGlide from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css'
Vue.use(VueGlide)


new Vue({
    el: "#app",
    components: {
        CsrfInput,
        HeaderCustom,
        Accordion,
        CardCarousel,
        CollapsePanel,
        Media
    }
});
