<template>
    <section
        :class="[
            {
                'card-carousel--large-image-highlight' : carouselType && !textHighlight,
                'card-carousel--large' : carouselType
            },
            'text-'+ textColor]
        "
        class="card-carousel pt-10 pb-12 lg:pt-16 lg:pb-20 overflow-hidden"
        :style="'background-color : '+ backgroundColor"
    >
        <div class="grid-container">
            <div class="grid-row">
                <div class="grid-col w-full mx-auto relative">
                    <vue-glide
                        :breakpoints="breakpoints"
                        v-model="currentIndex"
                        class="card-carousel__content"
                    >
                        <vue-glide-slide
                            v-for="(card, key) in cards"
                            :key="key"
                            :class="{
                                'px-7 pt-6 pb-10 lg:px-8 lg:pt-8 lg:pb-12 bg-grey-95' : textHighlight,
                                'bg-white filter drop-shadow rounded-md' : !textHighlight
                            }"
                            class="card overflow-hidden"
                        >
                            <div
                                :class="{
                                    'card__image--large' : carouselType,
                                    'card__image--image-highlight' : !textHighlight,
                                    'flex justify-center mb-4 lg:mb-6' : textHighlight,
                                }"
                                class="card__image overflow-hidden w-full"
                            >
                                <picture>
                                    <source v-if="card.imageDesktop && carouselType" :srcset="card.imageDesktop.url" media="(min-width: 768px)">
                                    <img
                                        v-if="card.imageMobile"
                                        :src="card.imageMobile.url"
                                        :alt="card.imageMobile.alt"
                                        :class="{'object-cover w-full h-full' : !textHighlight}"
                                    />
                                </picture>
                            </div>

                            <div
                                :class="{'items-center text-center' : textHighlight, 'p-4 md:p-6' : !textHighlight}"
                                class="card__text-content flex flex-col justify-between"
                            >
                                <div>
                                    <h2 :class="{'h2' : textHighlight, 'h4 uppercase' : !textHighlight}">{{ card.heading }}</h2>
                                    <div
                                        :class="{'body1 mt-4 lg:mt-6' : textHighlight, 'body2 mt-2 md:mt-4' : !textHighlight}"
                                        v-html="card.text"
                                    ></div>
                                </div>
                                <a
                                    v-if="card.cta && card.cta.value"
                                    :href="card.cta.value"
                                    :target="card.cta.target"
                                    :class="{'btn btn--black mt-6 lg:mt-8' : textHighlight, 'link link--S mt-9' : !textHighlight}"
                                    class="block "
                                >{{ card.cta.customText }}</a>
                            </div>
                        </vue-glide-slide>
                    </vue-glide>
                    <div class="card-carousel__controls flex justify-center items-center mt-6 lg:mt-8">
                        <button
                            :class="{'bg-grey-60' : currentIndex === 0, 'bg-black' : currentIndex !== 0}"
                            class="card-carousel__arrow card-carousel__prev w-12 h-12 rounded-full flex justify-center items-center flex-shrink-0"
                            @click="currentIndex--"
                        >
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.631 3.293.924 12l8.707 8.707 1.414-1.414L4.753 13h18.565v-2H4.753l6.292-6.293-1.414-1.414z" fill="#FFF" fill-rule="evenodd"/>
                            </svg>
                        </button>
                        <div class="card-carousel__dots flex items-center mx-4 lg:mx-8">
                            <button
                                v-for="(card, index, key) in cards"
                                :key="key + '-dot-'+ index"
                                :class="[
                                    'w-1/' + cards.length,
                                    {'rounded-l-full' : index === 0, 'rounded-r-full' : index === cards.length - 1, 'bg-black' : index === currentIndex}
                                ]"
                                class="h-1 bg-grey-60"
                                @click="currentIndex = index"
                            ></button>
                        </div>
                        <button
                            :class="{'bg-grey-60' : currentIndex === cards.length -1, 'bg-black' : currentIndex < cards.length -1}"
                            class="card-carousel__arrow card-carousel__next w-12 h-12 rounded-full flex justify-center items-center flex-shrink-0"
                            @click="currentIndex++"
                        >
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.369 3.293 23.076 12l-8.707 8.707-1.414-1.414L19.247 13H.682v-2h18.565l-6.292-6.293 1.414-1.414z" fill="#FFF" fill-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { Glide, GlideSlide } from 'vue-glide-js'

    export default {
        name : 'card-carousel',

        components: {
            [Glide.name]: Glide,
            [GlideSlide.name]: GlideSlide
        },

        props : {
            // The carousel cards
            cards : { type : Array, default : () => []},

            // Option to have large or small cards
            carouselType : { type : Boolean, default : false},

            // Option to highlight the text and not the iamge
            textHighlight : { type : Boolean, default : false},

            // Background color for the block
            backgroundColor : { type : String, default : 'light'},

            // Text color for the block
            textColor : { type : String, default : 'dark'}
        },

        data(){
            return {
                // Current index showed
                currentIndex : 0
            }
        },

        computed : {
            // Carousel options according to the breakpoint
            breakpoints(){
                return {
                    768 : {
                        perView : (!this.carouselType) ? 1.15 : 1,
                        gap : 16
                    },
                    1024 : {
                        perView : (!this.carouselType) ? 2.33 : 1.17,
                        gap : 16
                    },
                    1440 : {
                        perView : (!this.carouselType) ? 2.66 : 1.17,
                        gap : (!this.carouselType) ? 24 : 16,
                    },
                    1920 : {
                        perView : (!this.carouselType) ? 3 : 1.17,
                        gap : 24
                    }
                }
            }
        },

        watch : {
            // Watch change on the current index
            currentIndex(){
                // Prevent from going below 0 or above the card number
                if(this.currentIndex < 0) this.currentIndex = 0;
                else if (this.currentIndex > this.cards.length -1 ) this.currentIndex = this.cards.length -1;;
            }
        }
    }
</script>

<style lang="scss">
    .card-carousel {
        &__dots {
            width : 250px;
        }

        &--large-image-highlight {

            @screen md {
                .card-carousel__arrow {
                    @apply absolute transform -translate-y-1/2;
                    top : 230px;

                }

                .card-carousel__prev {
                    @apply left-0;
                }

                .card-carousel__next {
                    @apply right-0;
                }
            }

            @screen lg {
                .card-carousel__arrow {
                    top : 306px;
                }
            }
        }

        &--large {
            @screen md {
                .card-carousel__content {
                    margin-left : 8.33%;
                }
            }
        }

        .glide__slides, .glide__track {
            @apply overflow-visible;
        }

        .glide__slides {
            @apply items-stretch;
        }

        .glide__slide {
            @apply h-auto;
        }
    }

    .card {
        &__image {
            &--image-highlight {
                height : 220px;

                @screen lg {
                    height : 270px;
                }

                &.card__image--large {
                    height : 424px;

                    @screen md {
                        height : 460px;
                    }

                    @screen lg {
                        height : 612px;
                    }
                }
            }
        }
    }
</style>
